$color-grey-light: #ccc;
$color-gray-dark: #333;

$grayscale : 80%;

$shadow: 1rem 1rem 3rem rgba(0, 0, 0, 0.173);
$radius: 0;

*,
*::before,
*::after {
margin: 0;
padding: 0;
box-sizing: border-box;
}

section{
  margin-top: 6rem;
  margin-bottom: 6rem;
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: $shadow;
  border-radius: $radius;
}


.container{
  padding: 3rem
}


@media only screen and (min-width: 1000px) {
  .container{
    padding: 4rem;
    display: grid;
    grid-template-columns: [full-start] minmax(6rem, 1fr) [center-start] repeat(10, [col-start] minmax(min-content, 10rem) [col-end])[center-end] minmax(6rem, 1fr) [full-end] ;
    grid-template-rows: 6rem 75vh min-content;
    gap: 2rem;
  }

}


