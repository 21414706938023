.hero{
  margin-top: 2rem ;
  display: grid;
  
  &__text{
    display: grid;
    grid-template-rows: repeat(3, max-content);
    gap: 2rem;

    &-title{
      font-family: $font-display;
      
      display: grid;
      grid-template-rows: repeat(2, min-content);
      gap: 1rem;
    }
    
    &-intro{
      font-size: 4rem;
    }

    &-name{
      font-size: 5rem;
      background-color: #333;
      color:#fff;
      justify-self: start;
      box-shadow: $shadow;
      padding: 1rem 2rem;
    }

    &-story{
      // font-size: 2rem;
      text-align: justify;
      display: grid;
      gap: 1.5rem
    }
  }

  & img{
    width: 100%;
    box-shadow: $shadow;
    filter: grayscale($grayscale);
  }
}

////////////////////////////////////////////////////////////
//                MEDIA QUERIES
////////////////////////////////////////////////////////////
@media only screen and (min-width: 1000px) {
  .hero{
    padding: 8rem 0;
    grid-column: full-start / full-end;
  
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 5rem ;
  
    &__text{
      display: grid;
      gap: 3rem;
      justify-content: center;
      align-content: center;
      
      &-title{
        font-family: $font-display;
        font-size: 5vw;
      }
      
      &-story{
        font-size: 2rem;
        line-height: 1.5;
        display: grid;
        grid-template-rows: repeat(auto-fit, minmax(min-content, max-content));
        gap: 1rem;
      }
    }
  }
}

