.header{
  display: grid;
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;

  &__social{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 1.5rem;
  }

  &__icon{
    height:2.5rem;
    width:2.5rem;
    fill: $color-gray-dark;
  }
}


////////////////////////////////////////////////////////////
//                MEDIA QUERIES
////////////////////////////////////////////////////////////

@media only screen and (min-width: 1000px) {
 
  .header{
    grid-column: full-start / full-end;
    
    display: grid;
    grid-template-columns:repeat(2, max-content);
    justify-content: space-between;
    align-content: center;
    
    &__name{
      font-family: $font-display;
      font-size: 2.5rem;
    }
    
    &__social{
      display: grid;
      grid-template-columns: repeat(3, max-content);
      gap: 2rem;
    }
  }
}