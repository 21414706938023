.bio{
  grid-column: full-start / full-end;

  &__text{
    text-align: justify;
    display: grid;
    gap: 1.5rem;
    line-height: 1.5;
    
    &-story{
      font-size: 2rem;
      line-height: 1.5;
      display: grid;
      grid-template-rows: repeat(auto-fit, minmax(min-content, max-content));
      gap: 1rem;
    }
  }

  & img{
    width: 100%;
    box-shadow: $shadow;
    filter: grayscale($grayscale);
    
  }

}

.bio-story{
  display: grid;
  gap: 1rem;
}

////////////////////////////////////////////////////////////
//                MEDIA QUERIES
////////////////////////////////////////////////////////////

@media only screen and (min-width: 1000px) {
  .bio__text{
    &-content{
      display: grid;
      gap: 5rem;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      align-items: center;

    }
  }
}

@media only screen and (min-width: 1600px) {
  .bio{
    margin-top: 12rem;
  }
}
