@import "https://fonts.googleapis.com/css?family=League+Spartan:100,200,300,regular,500,600,700,800,900";
@import "https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic";
*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

img {
  object-fit: cover;
  border-radius: 0;
  width: 100%;
  height: 100%;
  box-shadow: 1rem 1rem 3rem #0000002c;
}

.container {
  padding: 3rem;
}

@media only screen and (min-width: 1000px) {
  .container {
    grid-template-rows: 6rem 75vh min-content;
    grid-template-columns: [full-start] minmax(6rem, 1fr)[center-start] repeat(10, [col-start] minmax(min-content, 10rem)[col-end])[center-end] minmax(6rem, 1fr)[full-end];
    gap: 2rem;
    padding: 4rem;
    display: grid;
  }
}

html {
  font-size: 62.5%;
}

body {
  color: #333;
  font-family: Montserrat;
  font-size: 2rem;
}

.header {
  grid-template-columns: repeat(2, min-content);
  justify-content: space-between;
  display: grid;
}

.header__social {
  grid-template-columns: repeat(3, min-content);
  gap: 1.5rem;
  list-style: none;
  display: grid;
}

.header__icon {
  fill: #333;
  width: 2.5rem;
  height: 2.5rem;
}

@media only screen and (min-width: 1000px) {
  .header {
    grid-column: full-start / full-end;
    grid-template-columns: repeat(2, max-content);
    place-content: center space-between;
    display: grid;
  }

  .header__name {
    font-family: League Spartan;
    font-size: 2.5rem;
  }

  .header__social {
    grid-template-columns: repeat(3, max-content);
    gap: 2rem;
    display: grid;
  }
}

.hero {
  margin-top: 2rem;
  display: grid;
}

.hero__text {
  grid-template-rows: repeat(3, max-content);
  gap: 2rem;
  display: grid;
}

.hero__text-title {
  grid-template-rows: repeat(2, min-content);
  gap: 1rem;
  font-family: League Spartan;
  display: grid;
}

.hero__text-intro {
  font-size: 4rem;
}

.hero__text-name {
  color: #fff;
  background-color: #333;
  justify-self: start;
  padding: 1rem 2rem;
  font-size: 5rem;
  box-shadow: 1rem 1rem 3rem #0000002c;
}

.hero__text-story {
  text-align: justify;
  gap: 1.5rem;
  display: grid;
}

.hero img {
  filter: grayscale(80%);
  width: 100%;
  box-shadow: 1rem 1rem 3rem #0000002c;
}

@media only screen and (min-width: 1000px) {
  .hero {
    grid-column: full-start / full-end;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 5rem;
    padding: 8rem 0;
    display: grid;
  }

  .hero__text {
    place-content: center;
    gap: 3rem;
    display: grid;
  }

  .hero__text-title {
    font-family: League Spartan;
    font-size: 5vw;
  }

  .hero__text-story {
    grid-template-rows: repeat(auto-fit, minmax(min-content, max-content));
    gap: 1rem;
    font-size: 2rem;
    line-height: 1.5;
    display: grid;
  }
}

.gallery {
  grid-auto-rows: min-content;
  gap: 1rem;
  display: grid;
}

.gallery img {
  box-shadow: 1rem 1rem 3rem #0000002c;
}

@media only screen and (min-width: 1000px) {
  .gallery {
    grid-column: full-start / full-end;
    grid-template-rows: repeat(6, 8vw);
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 8vw;
    gap: 10px;
    display: grid;
  }

  .gallery__item {
    box-shadow: 1rem 1rem 3rem #0000002c;
  }

  .gallery__item-1 {
    grid-area: 1 / 1 / span 2 / span 3;
  }

  .gallery__item-2 {
    grid-area: 1 / 4 / span 3 / span 2;
  }

  .gallery__item-3 {
    grid-area: 3 / 6 / span 2 / span 3;
  }

  .gallery__item-4 {
    grid-area: 1 / 6 / span 2 / span 2;
  }

  .gallery__item-5 {
    grid-area: 4 / 4 / span 2 / span 2;
  }

  .gallery__item-6 {
    grid-area: 3 / 1 / span 4 / span 3;
  }

  .gallery__item-7 {
    grid-area: 6 / 4 / 7 / span 2;
  }

  .gallery__item-8 {
    grid-area: 1 / 8 / 2 / span 2;
  }

  .gallery__item-9 {
    grid-area: 1 / 10 / span 2 / span 3;
  }

  .gallery__item-10 {
    grid-area: 5 / 8 / span 2 / span 3;
  }

  .gallery__item-11 {
    grid-area: 5 / 6 / span 2 / span 2;
  }

  .gallery__item-12 {
    grid-area: 2 / 8 / span 1 / span 2;
  }

  .gallery__item-13 {
    grid-area: 5 / 11 / span 2 / span 2;
  }

  .gallery__item-14 {
    grid-area: 3 / 9 / span 2 / span 2;
  }

  .gallery__item-15 {
    grid-area: 3 / 11 / span 2 / span 2;
  }

  .gallery__img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  }
}

.bio {
  grid-column: full-start / full-end;
}

.bio__text {
  text-align: justify;
  gap: 1.5rem;
  line-height: 1.5;
  display: grid;
}

.bio__text-story {
  grid-template-rows: repeat(auto-fit, minmax(min-content, max-content));
  gap: 1rem;
  font-size: 2rem;
  line-height: 1.5;
  display: grid;
}

.bio img {
  filter: grayscale(80%);
  width: 100%;
  box-shadow: 1rem 1rem 3rem #0000002c;
}

.bio-story {
  gap: 1rem;
  display: grid;
}

@media only screen and (min-width: 1000px) {
  .bio__text-content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    align-items: center;
    gap: 5rem;
    display: grid;
  }
}

@media only screen and (min-width: 1600px) {
  .bio {
    margin-top: 12rem;
  }
}

/*# sourceMappingURL=index.338c6724.css.map */
