@import url(https://fonts.googleapis.com/css?family=League+Spartan:100,200,300,regular,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

html {
  font-size: 62.5%;
}

body {
font-family: "Montserrat";
font-size: 2rem;
color: $color-gray-dark;
}

$font-display : "League Spartan";
$font-primary: "Monserrat";