.gallery{
  display: grid;
  grid-auto-rows: min-content;
  gap: 1rem;

  & img{
    box-shadow: $shadow;
  }
}

@media only screen and (min-width: 1000px) {
  .gallery{
    grid-column: full-start / full-end;
  
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 8vw);
    grid-auto-rows: 8vw;
    gap: 10;
  
    &__item{
      box-shadow: $shadow;
          
      &-1{
        grid-column: 1 / span 3;  
        grid-row: 1 / span 2;
      }
      
      &-2{
        grid-column: 4 / span 2;  
        grid-row: 1 / span 3;
      }
      

      &-3{
        grid-column: 6 / span 3;  
        grid-row: 3 / span 2;
      }
      
      &-4{
        grid-column: 6 / span 2;
        grid-row: 1 / span 2;
      }
      
      &-5{
        grid-column: 4 / span 2;
        grid-row: 4 / span 2;
      }
      
      &-6{
        grid-column: 1 / span 3;
        grid-row: 3 / span 4;
      }
      
      &-7{
        grid-column: 4 / span 2;
        grid-row: 6 / 7;
      }
      
      &-8{
        grid-column: 8 / span 2;
        grid-row: 1 / 2;
      }
      
      &-9{
        grid-column: 10 / span 3;
        grid-row: 1 / span 2;
      }
      
      &-10{
        grid-column: 8 / span 3;
        grid-row: 5 / span 2;
      }
      
      &-11{
        grid-column: 6 / span 2;
        grid-row: 5 / span 2;
      }
      
      &-12{
        grid-column: 8 / span 2;
        grid-row: 2 / span 1;
      }
      
      &-13{
        grid-column: 11 / span 2;
        grid-row: 5 / span 2;
      }
      
      &-14{
        grid-column: 9 / span 2;
        grid-row: 3 / span 2
      }
      
      &-15{
        grid-column: 11 / span 2;
        grid-row: 3 / span 2;
      }
    }
    
    &__img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}